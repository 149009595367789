
.access {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 30px auto;
    border: solid 1px black;
}

.access table {
    text-align: center;
    margin: 15px 0;
}
